import {
  Vue,
  Component,
  Action,
  Mutation,
  Getter,
  State
} from "nuxt-property-decorator";
import { Message } from 'byted-vui';
import TYPES from "~~/store/types";
import { index as api } from "~~/const/apis";
import { onlyFormRouteNames } from "~/utils/form";
import { ABTestConfig } from "~/store/index";
import { extendUrl } from "~/utils/redirect";
import { registerUserTTOauthExtraQueries } from "~/const/userRegister";

@Component
export default class App extends Vue {
  @State(state => state.onlyform.topUrlSearch) topUrlSearch: string;
  @State(state => state.onlyForm.topUrlParams) topUrlParams: {
    [key: string]: string;
  };

  @State(state => state.ABTestConfig) ABTestConfig: ABTestConfig;
  @State(state => state.isDynamicLP) isDynamicLP: boolean;
  @State(state => state.advABTestConfig) advABTestConfig: Record<string, any>;
  @Action("ssoCheckLogin") ssoCheckLogin: Function;
  @Action("config/getCommonConfig") getCommonConfig: Function;
  @Action("getLoginInfo") getLoginInfo: Function;
  @Action("setLoginCookie") setLoginCookie: Function;
  @Action("getAdvList") getAdvList: Function;
  @Action("getABTestConfigByAdvId") getABTestConfigByAdvId: Function;
  @Mutation(TYPES.SET_LOGIN) setLogin: Function;
  @Mutation(TYPES.SET_LOGIN_INFO) setLoginInfo: Function;
  @Mutation(TYPES.SET_LOGIN_CHECKED) setLoginChecked: Function;
  @Mutation(TYPES.SET_HAS_FETCH_LOGIN_INFO) setHasFetchLoginInfo: Function;
  @Mutation(TYPES.SET_ADV_LIST) setAdvList: Function;

  @Getter("isMobileDevice") isMobileDevice: boolean;

  get baseNextUrl() {
    return this.isMobileDevice ? window.mobileLiteLink : window.platformLink;
  }

  async _loginInfo () {
    // 改成异步跳转，不阻塞渲染表单
    try {
      const user = await this.getLoginInfo()
      if (!user?.id) {
        this.setAdvList([]);
        return;
      }
      // 从后台获取adv信息，已有adv账户的进行跳转
      await this.redirectIfHasAdv()
    } catch (e) {
      console.error(e)
    } finally {
      this.setHasFetchLoginInfo(true);
    }
  }

  async checkLogin(cb=()=>{}) {
    let isLogin = false;
    let ticket = "";
    try {
      const res = await this.ssoCheckLogin();
      isLogin = res?.isLogin || false;
      ticket = res?.ticket;
    } catch (error) {
      isLogin = false;
    }

    if (!isLogin) {
      this.setAdvList([]);
      this.setLoginInfo({});
      this.setLoginChecked(true);
      return;
    }

    try {
      const res =  await this.setLoginCookie({
        next: window.location.href, // 设置sessionID
        ticket
      });
      if(res?.message === 'error') {
        this.setAdvList([]);
        this.setLoginInfo({});
        Message({
          message: res?.data?.description || 'system error, please try again!',
          duration: 5000,
          type: 'error'
        })
        return;
      } else {
        this.setLogin(isLogin);
        this._loginInfo().then(cb)
      }
      this.setLoginChecked(true);
      console.log('setLoginCookie =====>', res)
    } catch (e) {
      window.SlardarWeb?.('sendEvent', {
        name: 'try_catch_error',
        categories: {
          errorMsg: e.message,
          errorStack: e.stack
        },
      });
    }
  }

  async checkAdvLogin() {
    try {
      this.setLoginChecked(true);
      this.setLogin(true);
      await this._loginInfo()
    } catch (e) {
      window.SlardarWeb?.('sendEvent', {
        name: 'try_catch_error',
        categories: {
          errorMsg: e.message,
          errorStack: e.stack
        },
      });
    }
  }

  // 检查用户user下是否有adv账户，有的话就跳转
  async redirectIfHasAdv() {
    // 自定义的lead收集表单无需跳转
    const isCustomLeadForm = this.$route.name === "lead-form-sign";
    // 广告主注册表单页，且url指定无需重定向逻辑
    const noNeedRedirectWhenlogedin = this.$route?.query?.no_redirect === "true";

    if (isCustomLeadForm || noNeedRedirectWhenlogedin) {
      this.setAdvList([]);
      return;
    }

    // 无需登录的路由
    const { data } = await this.getCommonConfig();
    const loginFree = data?.noLoginWhiteList?.includes(this.$route.name);
    if (loginFree) {
      this.setAdvList([]);
      return;
    }

    const advList = await this.getAdvList();

    if (advList.length > 0 && advList[0]?.id) {
      await this.getABTestConfigByAdvId(advList[0].id);
    }

    // for Libra AB testing，If there is a conflict between the advid_based split test  and the trace_sid split test,
    // the advid split test has a higher priority
    const isRedirectDisabledInAdvConfig = 'disabled_redirect' in this.advABTestConfig ? this.advABTestConfig.disabled_redirect : null;
    const isRedirectDisabledInABTestConfig = this.ABTestConfig.parameters?.redirectParams?.disabledRedirect ?? null;

    // do not redirect if is dynamic LP
    const needRedirect = !this.isDynamicLP
      && advList.length > 0
      && !(isRedirectDisabledInAdvConfig !== null ? isRedirectDisabledInAdvConfig : isRedirectDisabledInABTestConfig);

    // e2e的环境不用重定向走
    if (!needRedirect || this.$route.query?.__Cypress__) return;

    // 跳转逻辑，区分是否被iframe加载处理
    if (onlyFormRouteNames.includes(this.$route.name || "")) {
      this.$handshake?.redirect?.({
        url: extendUrl(this.baseNextUrl, this.topUrlParams)
      });
    } else {
      // 登录默认跳转不要带 tt oauth 参数
      this.$handleRedirection(
        this.baseNextUrl,
        this.topUrlParams,
        registerUserTTOauthExtraQueries
      );
    }
  }

  logout() {
    this.$commonReport(this.$TeaMap.switch_user_click);
    window.location.href = `${api.log_out}?service=https://${
      location.hostname
    }/passport/web/logout/?next=${encodeURIComponent(
      window.location.origin + window.location.pathname
    )}`;
  }
}
