import { Vue, Component } from "nuxt-property-decorator";
import { getLanguages } from "~~/const/locale";

@Component
export default class LangMixins extends Vue {
  get langs() {
    return getLanguages(this.$route.name);
  }

  get langsMap() {
    return this.langs.reduce((res: any, item: any) => {
      res[item.value] = item.label;
      return res;
    }, {});
  }
}
