import { Component, mixins } from "nuxt-property-decorator";
import LoginMixin from "./login";
@Component
export default class App extends mixins(LoginMixin) {
  created() {
    if (process.client) {
      this.checkLogin();
    }
  }
}
